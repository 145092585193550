import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from "@radix-ui/react-icons"
import { CaptionProps, DayPicker, useNavigation } from "react-day-picker"
import { Button } from "~/components/ui/button"
import { cn } from "~/utils/shadecn"
import { buttonVariants } from "~/components/ui/button"
import { addMonths, format, setMonth } from "date-fns"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select"
import { useEffect, useState } from "react"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function CustomCaption(props: CaptionProps) {
  const { goToMonth, currentMonth, nextMonth, previousMonth } = useNavigation()
  const [curMonth, setCurMonth] = useState(currentMonth.getMonth().toString())
  const [curYear, setCurYear] = useState(format(currentMonth, "yyy"))

  const yearStart = parseInt(format(addMonths(currentMonth, -100 * 12), "yyy"))
  const yearStop = parseInt(format(addMonths(currentMonth, 10 * 12), "yyy"))

  function onYearChange(val: string) {
    setCurYear(val)
    goToMonth(new Date(parseInt(val), currentMonth.getMonth()))
  }
  function onMonthChange(val: string) {
    setCurMonth(val)
    goToMonth(setMonth(currentMonth, parseInt(val)))
  }

  useEffect(() => {
    setCurMonth(currentMonth.getMonth().toString())
    setCurYear(format(currentMonth, "yyy"))
  }, [currentMonth])

  return (
    <h2 className="relative">
      <Button
        variant="outline"
        className="absolute left-1 space-x-1 pt-2.5 mt-[5px] p-1 h-6"
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}>
        <ChevronLeftIcon />
      </Button>
      <Button
        variant="outline"
        className="absolute right-1 space-x-1 pt-2.5 mt-[5px] p-1 h-6"
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}>
        <ChevronRightIcon />
      </Button>
      <div className="flex justify-center items-center pt-1.5">
        <Select value={curMonth} onValueChange={onMonthChange}>
          <SelectTrigger className="w-[45px] border-none shadow-none h-6 p-0 pl-1">
            <SelectValue placeholder="Month" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="0">Jan</SelectItem>
            <SelectItem value="1">Feb</SelectItem>
            <SelectItem value="2">Mar</SelectItem>
            <SelectItem value="3">Apr</SelectItem>
            <SelectItem value="4">May</SelectItem>
            <SelectItem value="5">Jun</SelectItem>
            <SelectItem value="6">Jul</SelectItem>
            <SelectItem value="7">Aug</SelectItem>
            <SelectItem value="8">Sep</SelectItem>
            <SelectItem value="9">Oct</SelectItem>
            <SelectItem value="10">Nov</SelectItem>
            <SelectItem value="11">Dec</SelectItem>
          </SelectContent>
        </Select>
        &nbsp;
        <Select value={curYear} onValueChange={onYearChange}>
          <SelectTrigger className="w-[55px] border-none shadow-none h-6 p-0 pl-1">
            <SelectValue placeholder="Year" />
          </SelectTrigger>
          <SelectContent>
            {Array.from({ length: (yearStop - yearStart) / 1 }, (value, index) => yearStart + index * 1).map((year) => (
              <SelectItem key={year} value={year.toString()}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </h2>
  )
}

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: cn(
          "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
          props.mode === "range" ?
            "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
          : "[&:has([aria-selected])]:rounded-md"
        ),
        day: cn(buttonVariants({ variant: "ghost" }), "h-8 w-8 p-0 font-normal aria-selected:opacity-100"),
        day_range_start: "day-range-start",
        day_range_end: "day-range-end",
        day_selected:
          "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside:
          "day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        Caption: CustomCaption,
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
