import { useField, useControlField, useFieldArray, useIsSubmitting   } from "remix-validated-form"
import { Input } from "~/components/ui/input"
import { Label } from "~/components/ui/label"
import { Button } from "~/components/ui/button"
import { CalendarIcon, Cross1Icon, ReloadIcon } from "@radix-ui/react-icons"
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "./ui/select"
import { Checkbox } from "~/components/ui/checkbox"
import { Textarea } from "~/components/ui/textarea"
import { useEffect, useRef, useState } from "react"
import { useFetcher } from "@remix-run/react"
import type { loader } from "~/routes/_app+/uploads"
import axios from "axios"
import { Progress } from "~/components/ui/progress"
import { cn } from "~/utils/shadecn"
import { Calendar } from "~/components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "~/components/ui/popover"
import dayjs from "dayjs"
import { Command, CommandGroup, CommandItem } from "~/components/ui/command"
import { Badge } from "~/components/ui/badge"
import { Command as CommandPrimitive } from "cmdk"

export interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  className?: string
  inputClasses?: string
  autoFocus?: boolean
  disabled?: boolean
}

export interface FormFileProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  defaultValue?: string
  accept: string
  sizeLimit: { size: number; label: string }
  clear?: boolean
  setClear?: (state: boolean) => void
  url: string
  setUrl: (url: string) => void
  onSetDuration?: (duration: number) => void
  label?: string
  inputClasses?: string
  disabled?: boolean
}

export interface FormDateProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string
  label?: string
  defaultDate?: string
  disabled?: boolean
}

export interface FormTextProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string
  label?: string
  inputClasses?: string
  inputSize?: string
  placeholder?: string
  autoFocus?: boolean
  disabled?: boolean
}

export interface FormSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  label?: string
  value?: string
  prefix?: string
  setValue?: (value: string) => void
  options: { value: string; label: string }[]
  disabled?: boolean
  className?: string
}

interface option {
  value: string
  label: string
}

export interface FormMultiSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  label?: string
  prefix?: string
  placeholder?: string
  setValues?: (values: option[]) => void
  options: option[]
  disabled?: boolean
}

export const FormInput = ({ type, name, label, prefix, className, inputClasses, disabled = false }: FormInputProps) => {
  const { error, getInputProps } = useField(name)

  return (
    <div className={cn("space-y-2", className)}>
      <div className={cn(disabled ? "peer-disabled:opacity-70" : "")}>
        {type !== "hidden" && label && (
          <Label className={cn("mx-1", disabled && "opacity-50")} htmlFor={name}>
            {label}
          </Label>
        )}
        <div className="relative">
          {prefix && <div className="absolute left-2 top-1.5 text-[16px]">{prefix}</div>}
          <Input type={type} {...getInputProps({ id: name })} className={inputClasses} disabled={disabled} />
        </div>
        {error && <span className="text-[0.8rem] font-medium text-destructive">{error}</span>}
      </div>
    </div>
  )
}

export const FormFile = ({
  name,
  label,
  accept,
  sizeLimit,
  clear,
  setClear,
  url,
  setUrl,
  onSetDuration,
  inputClasses,
  disabled = false,
}: FormFileProps) => {
  const { error } = useField(name)
  const durationField = useField(`${name}Duration`)
  let fetcher = useFetcher<typeof loader>()
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(0)
  const [durationProcessing, setDurationProcessing] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [uploadError, setUploadError] = useState("")

  const handleUpload = (e: any) => {
    const file = e.target.files![0]
    setProgress(0)
    setDurationProcessing(true)
    setUploadError("")
    setFile(file)

    if (file.size > sizeLimit.size) {
      setUploadError(
        `Invalid file. Please upload ${accept
          .split(",")
          .map((ftype) => ftype.replace(/^\./, ""))
          .join(", ")} files with maximum size ${sizeLimit.label}.`
      )
      return false
    }

    axios.get(`/uploads?type=${file.name.split(".").pop()}`).then(function (signed) {
      var config = {
        onUploadProgress: function (e: any) {
          setProgress(Math.round((e.loaded * 100) / e.total))
        },
      }

      axios
        .put(signed.data.result.signedRequest, file, config)
        .then(function (res) {
          setUrl(signed.data.result.url)

          if (onSetDuration) {
            const video = document.createElement("video")
            video.preload = "metadata"
            video.onloadedmetadata = function () {
              window.URL.revokeObjectURL(video.src)
              setDuration(Math.floor(video.duration))
              setDurationProcessing(false)
            }
            video.src = URL.createObjectURL(file)
          }
        })
        .catch(function (err) {
          setUploadError(`error uploading file: ${err}`)
          setDurationProcessing(false)
        })
    })
  }

  useEffect(() => {
    if (clear) {
      setProgress(0)
      setDuration(0)
      setDurationProcessing(false)
      setUploadError("")
      setFile(null)
      setUrl("")
      if (setClear) setClear(false)
    }
  }, [clear])

  useEffect(() => {
    if (onSetDuration && duration > 0) {
      durationField.validate()
      onSetDuration(duration)
    }
  }, [duration])

  return (
    <div className="space-y-2 relative">
      {onSetDuration && (duration || durationProcessing) && (
        <div className="absolute right-0 top-1.5 font-medium text-xs text-muted-foreground">
          duration:{" "}
          {durationProcessing ?
            <span>
              <ReloadIcon className="inline h-3 w-3 animate-spin" />
            </span>
          : `${duration}s`}
        </div>
      )}
      {label && (
        <Label className={cn("mx-1", disabled && "opacity-50")} htmlFor={name}>
          {label}
        </Label>
      )}
      <div className="relative">
        <Input
          type="file"
          className={cn("px-2 pt-1.5 pb-1 bg-background", inputClasses)}
          accept={accept}
          onChange={handleUpload}
          disabled={disabled}
        />
        <Input type="hidden" id={name} name={name} value={url} onChange={(e) => setUrl(e.target.value)} />
        <Input
          type="hidden"
          id={`${name}Duration`}
          name={`${name}Duration`}
          value={duration}
          onChange={(e) => setDuration(parseInt(e.target.value))}
        />
        <Progress
          className={cn("rounded-none rounded-b-full -top-[3px] mx-[1px] w-auto", progress == 0 && "hidden")}
          value={progress}
        />
      </div>
      {error && <span className="text-[0.8rem] font-medium text-destructive">{error}</span>}
      {durationField.error && <span className="text-[0.8rem] font-medium text-destructive">{durationField.error}</span>}
      {uploadError && <span className="text-[0.8rem] font-medium text-destructive">{uploadError}</span>}
    </div>
  )
}

export const FormText = ({
  name,
  label,
  placeholder,
  autoFocus,
  prefix,
  inputClasses,
  inputSize,
  disabled = false,
}: FormTextProps) => {
  const { error, getInputProps } = useField(name, {
    validationBehavior: { initial: "onChange", whenTouched: "onChange", whenSubmitted: "onChange" },
  })
  return (
    <div className="space-y-2">
      {label && (
        <Label className={cn("mx-1", disabled && "opacity-50")} htmlFor={name}>
          {label}
        </Label>
      )}
      <div className="relative">
        {prefix && <div className="absolute left-2 top-2 text-sm">{prefix}</div>}
        <Textarea
          size={inputSize || "default"}
          placeholder={placeholder}
          {...getInputProps({ id: name })}
          autoFocus={autoFocus}
          className={inputClasses}
          disabled={disabled}
        />
      </div>
      {error && <span className="text-[0.8rem] font-medium text-destructive">{error}</span>}
    </div>
  )
}

export const FormDate = ({ name, label, defaultDate, disabled = false }: FormDateProps) => {
  const { error, getInputProps } = useField(name)
  const [date, setDate] = useState(defaultDate || getInputProps().defaultValue || "")

  return (
    <div className="space-y-2">
      {label && (
        <Label className={cn("mx-1", disabled && "opacity-50")} htmlFor={name}>
          {label}
        </Label>
      )}
      <Input type="hidden" id={name} name={name} value={date} onChange={(e) => setDate(e.target.value)} />
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn("w-full pl-3 text-left font-normal", !date && "text-muted-foreground")}
            disabled={disabled}>
            {date ?
              <span>{date.toString()}</span>
            : <span>Pick a date</span>}
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={new Date(date)}
            onSelect={(value) => setDate(dayjs(value).format("YYYY-MM-DD"))}
          />
        </PopoverContent>
      </Popover>
      {error && <span className="text-[0.8rem] font-medium text-destructive">{error}</span>}
    </div>
  )
}

export const FormSelect = ({
  name,
  label,
  setValue,
  placeholder,
  options,
  disabled = false,
  className,
}: FormSelectProps) => {
  const { error, getInputProps, validate } = useField(name)
  const [formValue, setFormValue] = useControlField<string>(name)

  function onChange(val: string) {
    if (val == placeholder || val == label) {
      setFormValue("")
      if (setValue) setValue("")
    } else {
      setFormValue(val)
      if (setValue) setValue(val)
    }

    validate()
  }

  return (
    <div className={cn(className, disabled && "opacity-50 pointer-events-none")}>
      {label && (
        <Label className={cn("mb-3 mx-1 block", disabled && "opacity-50")} htmlFor={name}>
          {label}
        </Label>
      )}
      <Select value={formValue?.toString()} onValueChange={onChange} {...getInputProps()}>
        <SelectTrigger>
          <SelectValue placeholder={placeholder || label} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{label}</SelectLabel>
            {options.map((option, index) => (
              <SelectItem key={index} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      {error && <span className="text-[0.8rem] font-medium text-destructive">{error}</span>}
    </div>
  )
}

export const FormMultiSelect = ({
  name,
  label,
  placeholder,
  options,
  disabled = false,
  className,
}: FormMultiSelectProps) => {
  const [items, { push, remove }, error] = useFieldArray(name)
  const inputRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<option[]>(items.map((i) => i.defaultValue) || [])
  const [inputValue, setInputValue] = useState("")

  const handleUnselect = (option: option) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i] === option.value) {
        remove(i)
      }
    }
    setSelected((prev) => prev.filter((s) => s.value !== option.value))
  }

  const handleClearAll = () => {
    items.forEach((_, index) => remove(index))
    setSelected([])
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && !inputValue && selected.length > 0) {
      const lastOption = selected[selected.length - 1]
      handleUnselect(lastOption)
    }
  }

  const selectablesStrs = selected.map((item) => JSON.stringify(item))
  const selectables = options.filter((option) => !selectablesStrs.includes(JSON.stringify(option)))

  return (
    <div className={className}>
      {label && (
        <Label className={cn("mb-3 mx-1 block", disabled && "opacity-50")} htmlFor={name}>
          {label}
        </Label>
      )}
      {selected.map((item, index) => (
        <div key={index}>
          <Input type="hidden" id={`${name}[${index}]`} name={`${name}[${index}]`} value={item.value} />
        </div>
      ))}
      <Command className={cn("overflow-visible bg-transparent", disabled && "opacity-50 pointer-events-none")}>
        <div className="group border border-input px-3 py-2 text-sm ring-offset-background rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
          <div className="flex gap-1 flex-wrap">
            {selected.map((option, index) => {
              return (
                <Badge key={index}>
                  {option.label}
                  <button
                    className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onClick={(e) => {
                      handleUnselect(option)
                      e.preventDefault()
                      e.stopPropagation()
                    }}>
                    <Cross1Icon className="h-3 w-3 ml-1 text-foreground" />
                  </button>
                </Badge>
              )
            })}
            <CommandPrimitive.Input
              ref={inputRef}
              value={inputValue}
              onKeyDown={handleKeyDown}
              onValueChange={setInputValue}
              onBlur={() => setOpen(false)}
              onFocus={() => setOpen(true)}
              placeholder={placeholder}
              className="ml-2 bg-transparent outline-none placeholder:text-muted-foreground flex-1"
            />
          </div>
        </div>
        <div className="relative mt-2">
          {open && selectables.length > 0 ?
            <div className="absolute w-full z-10 top-0 rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
              <CommandGroup className="h-96 overflow-scroll">
                {selectables.map((option) => {
                  return (
                    <CommandItem
                      key={option.value}
                      onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onSelect={(value) => {
                        setInputValue("")
                        push(option)
                        setSelected((prev) => [...prev, option])
                      }}
                      className={"cursor-pointer"}>
                      {option.label}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            </div>
          : null}
        </div>
      </Command>
      <div className="flex justify-between items-center mb-3 mx-1">
        {selected.length > 0 && (
          <Button
            type="button"
            variant="ghost"
            size="sm"
            className="h-auto p-0 text-xs text-muted-foreground hover:text-foreground"
            onClick={handleClearAll}>
            Clear all
          </Button>
        )}
      </div>
      {error && <span className="text-[0.8rem] font-medium text-destructive">{error}</span>}
    </div>
  )
}

export const FormCheckbox = ({
  name,
  label,
  value,
  children,
  disabled = false,
  className,
}: {
  name: string
  label?: string
  value?: boolean
  children?: any
  disabled?: boolean
  className?: string
}) => {
  const { error, getInputProps } = useField(name)

  return (
    <div className={className}>
      <div className="flex items-center">
        <Checkbox id={name} name={name} defaultChecked={value} disabled={disabled} />
        <label
          htmlFor={name}
          className={cn(
            "text-sm pl-2 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
            disabled && "opacity-50 pointer-events-none"
          )}>
          {children ? children : label}
        </label>
      </div>

      {error && <span className="text-[0.8rem] font-medium text-destructive">{error}</span>}
    </div>
  )
}

export const SubmitButton = ({
  content,
  className,
  loading,
  formId,
  size,
  onClick,
  disabled = false,
}: {
  content: string
  className?: string
  loading: string
  formId?: string
  size?: string
  onClick?: any
  disabled?: boolean
}) => {
  const isSubmitting = useIsSubmitting(formId)
  return (
    <Button
      type="submit"
      size={size || "default"}
      className={className}
      onClick={onClick}
      disabled={isSubmitting || disabled}>
      {isSubmitting ?
        <span>
          <ReloadIcon className="inline mr-2 h-4 w-4 animate-spin" />
          {loading}
        </span>
      : content}
    </Button>
  )
}
